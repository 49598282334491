export const VERSIONS = 'VERSIONS';
export const GET_VERSIONS = 'GET/VERSIONS';
export const GET_VERSIONS_SUCCESS = 'GET/VERSIONS/SUCCESS';
export const GET_VERSIONS_FAILED = 'GET/VERSIONS/FAILED';
export const PINNED_VERSIONS = 'PINNED_VERSIONS';
export const GET_PINNED_VERSIONS = 'GET/PINNED_VERSIONS';
export const GET_PINNED_VERSIONS_SUCCESS = 'GET/PINNED_VERSIONS/SUCCESS';
export const GET_PINNED_VERSIONS_FAILED = 'GET/PINNED_VERSIONS/FAILED';
export const PINNED_VERSION = 'PINNED_VERSION';
export const SAVE_PINNED_VERSION = 'SAVE/PINNED_VERSION';
export const SAVE_PINNED_VERSION_SUCCESS = 'SAVE/PINNED_VERSION/SUCCESS';
export const SAVE_PINNED_VERSION_FAILED = 'SAVE/PINNED_VERSION/FAILED';
