import { 
  GET_DEBUG_EXPORTS, 
  GET_DEBUG_EXPORTS_SUCCESS, 
  GET_DEBUG_EXPORTS_FAILED, 
  LAUNCH_DEBUG_VM, 
  GET_DEBUG_EXPORTS_PERIODICALLY,
  UPDATE_DEBUG_EXPORT,
  UPDATE_DEBUG_EXPORT_SUCCESS,
  UPDATE_DEBUG_EXPORT_FAILED,
  SET_DEBUG_EXPORT,
} from './constants';

export const getDebugExports = (page = 1) => ({
  type: GET_DEBUG_EXPORTS,
  payload: {
    page
  }
});

export const getDebugExportsPeriodically = (page = 1) => ({
  type: GET_DEBUG_EXPORTS_PERIODICALLY,
  payload: {
    page,
    periodic: true
  }
})

export const getDebugExportsSuccess = (debug_exports, pagination) => ({
  type: GET_DEBUG_EXPORTS_SUCCESS,
  payload: {
    debug_exports,
    pagination,
  },
});

export const getDebugExportsFailed = (error) => ({
  type: GET_DEBUG_EXPORTS_FAILED,
  payload: {
    error,
  },
});

export const launchDebugVM = (vm) => ({
  type: LAUNCH_DEBUG_VM,
  payload: {
    vm
  }
});

export const setDebugExport = (debugExport) => ({
  type: SET_DEBUG_EXPORT,
  payload: { debugExport },
});

export const updateDebugExport = (debugExportId, notes) => ({
  type: UPDATE_DEBUG_EXPORT,
  payload: {
    debugExportId,
    notes,
  },
});

export const updateDebugExportSuccess = (debugExport) => ({
  type: UPDATE_DEBUG_EXPORT_SUCCESS,
  payload: { debugExport },
});

export const updateDebugExportFailed = (error) => ({
  type: UPDATE_DEBUG_EXPORT_FAILED,
  payload: {
    error,
  },
});
