import {
  GET_VERSIONS,
  GET_VERSIONS_SUCCESS,
  GET_VERSIONS_FAILED,
  GET_PINNED_VERSIONS,
  GET_PINNED_VERSIONS_SUCCESS,
  GET_PINNED_VERSIONS_FAILED,
  SAVE_PINNED_VERSION,
  SAVE_PINNED_VERSION_SUCCESS,
  SAVE_PINNED_VERSION_FAILED,
} from './constants';

export const getVersions = (page = 1, search = {}, filters = {}, sort = {}) => ({
  type: GET_VERSIONS,
  payload: {
    page,
    search,
    filters,
    sort,
  },
});

export const getVersionsSuccess = (versions, pagination) => ({
  type: GET_VERSIONS_SUCCESS,
  payload: {
    versions,
    pagination,
  },
});

export const getVersionsFailed = (error) => ({
  type: GET_VERSIONS_FAILED,
  payload: {
    error,
  },
});

export const getPinnedVersions = () => ({
  type: GET_PINNED_VERSIONS,
});

export const getPinnedVersionsSuccess = (versions) => ({
  type: GET_PINNED_VERSIONS_SUCCESS,
  payload: {
    versions,
  },
});

export const getPinnedVersionsFailed = (error) => ({
  type: GET_PINNED_VERSIONS_FAILED,
  payload: {
    error,
  },
});

export const togglePin = (versionId, status) => ({
  type: SAVE_PINNED_VERSION,
  payload: {
    versionId,
    status,
  },
});

export const togglePinSuccess = (version) => ({
  type: SAVE_PINNED_VERSION_SUCCESS,
  payload: {
    version,
  },
});

export const togglePinFailed = (error) => ({
  type: SAVE_PINNED_VERSION_FAILED,
  payload: {
    error,
  },
});
