export const DEVICES = 'DEVICES';
export const DEVICE = 'DEVICE';

export const GET_DEVICES = 'GET/DEVICES';
export const GET_DEVICES_SUCCESS = 'GET/DEVICES/SUCCESS';
export const GET_DEVICES_FAILED = 'GET/DEVICES/FAILED';

export const SET_DEVICE = 'SET/DEVICES';

export const UPDATE_DEVICE = 'SAVE/DEVICE';
export const UPDATE_DEVICE_SUCCESS = 'SAVE/DEVICE/SUCCESS';
export const UPDATE_DEVICE_FAILED = 'SAVE/DEVICE/FAILED';

export const FILTER_DEVICES = 'FILTER/DEVICES';
export const SORT_DEVICES = 'SORT/DEVICES';
export const TOGGLE_FILTERS = 'TOGGLE_FILTERS/DEVICES';

export const GET_DEVICE = 'GET/DEVICE';
export const GET_DEVICE_SUCCESS = 'GET/DEVICE/SUCCESS';
export const GET_DEVICE_FAILED = 'GET/DEVICE/FAILED';

export const DEVICE_TEMPLATES = 'DEVICE_TEMPLATES';
export const GET_DEVICE_TEMPLATES = 'GET/DEVICE_TEMPLATES';
export const GET_DEVICE_TEMPLATES_FAILED = 'GET/DEVICE_TEMPLATES/FAILED';
export const GET_DEVICE_TEMPLATES_SUCCESS = 'GET/DEVICE_TEMPLATES/SUCCESS';

export const DEVICE_GROUPS = 'DEVICE_GROUPS';
export const GET_DEVICE_GROUPS = 'GET/DEVICE_GROUPS';
export const GET_DEVICE_GROUPS_SUCCESS = 'GET/DEVICE_GROUPS/SUCCESS';
export const GET_DEVICE_GROUPS_FAILED = 'GET/DEVICE_GROUPS/FAILED';

export const CREATE_DEVICE_GROUPS = 'CREATE/DEVICE_GROUPS';
export const CREATE_DEVICE_GROUPS_SUCCESS = 'CREATE/DEVICE_GROUPS/SUCCESS';
export const CREATE_DEVICE_GROUPS_FAILED = 'CREATE/DEVICE_GROUPS/FAILED';

export const UPDATE_DEVICE_GROUPS = 'UPDATE/DEVICE_GROUPS';
export const UPDATE_DEVICE_GROUPS_SUCCESS = 'UPDATE/DEVICE_GROUPS/SUCCESS';
export const UPDATE_DEVICE_GROUPS_FAILED = 'UPDATE/DEVICE_GROUPS/FAILED';

export const DELETE_DEVICE_GROUPS = 'DELETE/DEVICE_GROUPS';
export const DELETE_DEVICE_GROUPS_SUCCESS = 'DELETE/DEVICE_GROUPS/SUCCESS';
export const DELETE_DEVICE_GROUPS_FAILED = 'DELETE/DEVICE_GROUPS/FAILED';

export const DEVICE_GROUP_MEMBERSHIP = 'DEVICE_GROUP_MEMBERSHIP';
export const GET_DEVICE_GROUP_MEMBERSHIP = 'GET/DEVICE_GROUP_MEMBERSHIP';
export const GET_DEVICE_GROUP_MEMBERSHIP_SUCCESS = 'GET/DEVICE_GROUP_MEMBERSHIP/SUCCESS';
export const GET_DEVICE_GROUP_MEMBERSHIP_FAILED = 'GET/DEVICE_GROUP_MEMBERSHIP/FAILED';

export const DELETE_DEVICE_GROUP_MEMBERSHIP = 'DELETE/DEVICE_GROUP_MEMBERSHIP';
export const DELETE_DEVICE_GROUP_MEMBERSHIP_SUCCESS = 'DELETE/DEVICE_GROUP_MEMBERSHIP/SUCCESS';
export const DELETE_DEVICE_GROUP_MEMBERSHIP_FAILED = 'DELETE/DEVICE_GROUP_MEMBERSHIP/FAILED';
