import Navbar from '../components/Navbar';
import React, { useState, Suspense } from 'react';
import SideMenu from '../components/SideMenu';
import { Container } from 'reactstrap';

export const Main = (props) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const loading = () => <div className="text-center" />;

  return (
    <div className="app">
      <Navbar toggleMenu={() => setIsMenuOpened(!isMenuOpened)} />

      <SideMenu isMenuOpened={isMenuOpened} />

      <Container className="app-container">
        <Suspense fallback={loading()}>{props.children}</Suspense>
      </Container>
    </div>
  );
};

export default Main;
