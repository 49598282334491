import {
  GET_USERS,
  GET_USERS_SUCCESS,
  SET_CURRENT_USER,
  GET_USER_SUCCESS,
  CREATE_USER_ROLE,
  CREATE_USER_ROLE_SUCCESS,
  GET_USER_ROLES_SUCCESS,
} from './constants';

const INIT_STATE = {
  usersByEmail: {},
  users: [],
  currentUser: null,
  pagination: null,
  userRoles: [],
  selectedUser: {},
};

export const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        usersByEmail: {},
        users: [],
        pagination: null,
      };
    case GET_USERS_SUCCESS:
      const successUsersById = {};
      const successUsers = action.payload.users.map((v) => {
        successUsersById[v.email] = v;
        return v.email;
      });

      return {
        ...state,
        usersByEmail: successUsersById,
        users: successUsers,
        pagination: action.payload.pagination,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload.user,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        selectedUser: action.payload.user,
      };
    case GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        userRoles: action.payload.roles,
      };
    case CREATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        userRoles: action.payload.roles,
      };
    default:
      return state;
  }
};

export default User;
