import {
  CREATE_DEVICE_GROUPS,
  CREATE_DEVICE_GROUPS_FAILED,
  CREATE_DEVICE_GROUPS_SUCCESS,
  DELETE_DEVICE_GROUP_MEMBERSHIP,
  DELETE_DEVICE_GROUP_MEMBERSHIP_FAILED,
  DELETE_DEVICE_GROUP_MEMBERSHIP_SUCCESS,
  DELETE_DEVICE_GROUPS,
  DELETE_DEVICE_GROUPS_FAILED,
  DELETE_DEVICE_GROUPS_SUCCESS,
  FILTER_DEVICES,
  GET_DEVICE,
  GET_DEVICE_FAILED,
  GET_DEVICE_GROUP_MEMBERSHIP,
  GET_DEVICE_GROUP_MEMBERSHIP_FAILED,
  GET_DEVICE_GROUP_MEMBERSHIP_SUCCESS,
  GET_DEVICE_GROUPS,
  GET_DEVICE_GROUPS_FAILED,
  GET_DEVICE_GROUPS_SUCCESS,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_TEMPLATES,
  GET_DEVICE_TEMPLATES_FAILED,
  GET_DEVICE_TEMPLATES_SUCCESS,
  GET_DEVICES,
  GET_DEVICES_FAILED,
  GET_DEVICES_SUCCESS,
  SET_DEVICE,
  SORT_DEVICES,
  TOGGLE_FILTERS,
  UPDATE_DEVICE,
  UPDATE_DEVICE_FAILED,
  UPDATE_DEVICE_GROUPS,
  UPDATE_DEVICE_GROUPS_FAILED,
  UPDATE_DEVICE_GROUPS_SUCCESS,
  UPDATE_DEVICE_SUCCESS,
} from './constants';

export const getDevices = (page = 1, search = {}, filters = {}, sort = {}) => ({
  type: GET_DEVICES,
  payload: {
    page,
    search,
    filters,
    sort,
  },
});

export const getDevicesSuccess = (devices, pagination) => ({
  type: GET_DEVICES_SUCCESS,
  payload: {
    devices,
    pagination,
  },
});

export const getDevicesFailed = (error) => ({
  type: GET_DEVICES_FAILED,
  payload: { error },
});

export const getDevice = (serial) => ({
  type: GET_DEVICE,
  payload: { serial },
});

export const getDeviceSuccess = (device) => ({
  type: GET_DEVICE_SUCCESS,
  payload: { device },
});

export const getDeviceFailed = (error) => ({
  type: GET_DEVICE_FAILED,
  payload: { error },
});

export const getDeviceTemplates = (deviceType) => ({
  type: GET_DEVICE_TEMPLATES,
  payload: { deviceType },
});

export const getDeviceTemplatesSuccess = (deviceType, managedApplications) => ({
  type: GET_DEVICE_TEMPLATES_SUCCESS,
  payload: { deviceType, managedApplications },
});

export const getDeviceTemplatesFailed = (error) => ({
  type: GET_DEVICE_TEMPLATES_FAILED,
  payload: { error },
});

export const getDeviceGroupMembership = (groupId) => ({
  type: GET_DEVICE_GROUP_MEMBERSHIP,
  payload: { groupId },
});

export const getDeviceGroupMembershipSuccess = (groupId, devices) => ({
  type: GET_DEVICE_GROUP_MEMBERSHIP_SUCCESS,
  payload: { groupId, devices },
});

export const getDeviceGroupMembershipFailed = (error) => ({
  type: GET_DEVICE_GROUP_MEMBERSHIP_FAILED,
  payload: { error },
});

export const deleteDeviceGroupMembership = (groupId, groupName, deviceId) => ({
  type: DELETE_DEVICE_GROUP_MEMBERSHIP,
  payload: { groupId, groupName, deviceId },
});

export const deleteDeviceGroupMembershipSuccess = (groupId, deviceId) => ({
  type: DELETE_DEVICE_GROUP_MEMBERSHIP_SUCCESS,
  payload: { groupId, deviceId },
});

export const deleteDeviceGroupMembershipFailed = (error) => ({
  type: DELETE_DEVICE_GROUP_MEMBERSHIP_FAILED,
  payload: { error },
});

export const getDeviceGroups = () => ({
  type: GET_DEVICE_GROUPS,
});

export const getDeviceGroupsSuccess = (groups) => ({
  type: GET_DEVICE_GROUPS_SUCCESS,
  payload: { groups },
});

export const getDeviceGroupsFailed = (error) => ({
  type: GET_DEVICE_GROUPS_FAILED,
  payload: { error },
});

export const deleteDeviceGroups = (groupId) => ({
  type: DELETE_DEVICE_GROUPS,
  payload: { groupId },
});

export const deleteDeviceGroupsSuccess = (groupId) => ({
  type: DELETE_DEVICE_GROUPS_SUCCESS,
  payload: { groupId },
});

export const deleteDeviceGroupsFailed = (error) => ({
  type: DELETE_DEVICE_GROUPS_FAILED,
  payload: { error },
});

export const createDeviceGroups = (groupName, deviceSerials) => ({
  type: CREATE_DEVICE_GROUPS,
  payload: {
    groupName,
    deviceSerials,
  },
});

export const createDeviceGroupsSuccess = (deviceGroup) => ({
  type: CREATE_DEVICE_GROUPS_SUCCESS,
  payload: { deviceGroup },
});

export const createDeviceGroupsFailed = (error) => ({
  type: CREATE_DEVICE_GROUPS_FAILED,
  payload: { error },
});

export const updateDeviceGroups = (groupId, groupName, deviceSerials) => ({
  type: UPDATE_DEVICE_GROUPS,
  payload: {
    groupId,
    groupName,
    deviceSerials,
  },
});

export const updateDeviceGroupsSuccess = (deviceGroup) => ({
  type: UPDATE_DEVICE_GROUPS_SUCCESS,
  payload: { deviceGroup },
});

export const updateDeviceGroupsFailed = (error) => ({
  type: UPDATE_DEVICE_GROUPS_FAILED,
  payload: { error },
});

export const setDevice = (device) => ({
  type: SET_DEVICE,
  payload: { device },
});

export const updateDevice = (deviceId, orgId, notes, additionalData) => ({
  type: UPDATE_DEVICE,
  payload: {
    orgId,
    notes,
    deviceId,
    additionalData,
  },
});

export const updateDeviceSuccess = (device) => ({
  type: UPDATE_DEVICE_SUCCESS,
  payload: { device },
});

export const updateDeviceFailed = (error) => ({
  type: UPDATE_DEVICE_FAILED,
  payload: { error },
});

export const setDeviceFilters = (filters) => ({
  type: FILTER_DEVICES,
  payload: { filters },
});

export const setDeviceSort = (sortField, sortDirection) => ({
  type: SORT_DEVICES,
  payload: {
    sortField,
    sortDirection,
  },
});

export const toggleDeviceShowFilters = () => ({
  type: TOGGLE_FILTERS,
});
