import {
  FILTER_DEVICES,
  GET_DEVICES,
  GET_DEVICES_SUCCESS,
  SET_DEVICE,
  SORT_DEVICES,
  TOGGLE_FILTERS,
  UPDATE_DEVICE_SUCCESS,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_TEMPLATES_SUCCESS,
  GET_DEVICE_GROUPS_SUCCESS,
  UPDATE_DEVICE_GROUPS_SUCCESS,
  DELETE_DEVICE_GROUPS_SUCCESS,
  CREATE_DEVICE_GROUPS_SUCCESS,
  GET_DEVICE_GROUP_MEMBERSHIP_SUCCESS,
  DELETE_DEVICE_GROUP_MEMBERSHIP_SUCCESS,
} from './constants';

const INIT_STATE = {
  devicesBySerial: {},
  devices: [],
  sortField: 'created_at',
  sortDirection: 'desc',
  filters: {},
  showFilters: false,
  selectedDevice: {},
  managedApplications: {},
  deviceGroupsError: null,
  deviceGroups: [],
  deviceGroupMembership: {},

  pagination: {
    total: 0,
    page: 1,
    pages: 1,
  },
};

export const Device = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICES:
      return {
        ...state,
        devicesBySerial: {},
        devices: [],
        pagination: {},
      };
    case GET_DEVICES_SUCCESS: {
      const devicesBySerial = {};
      const devices = action.payload.devices.map((d) => {
        devicesBySerial[d.serial] = d;
        return d.serial;
      });

      return {
        ...state,
        devicesBySerial,
        devices,
        pagination: action.payload.pagination,
      };
    }
    case UPDATE_DEVICE_SUCCESS:
    case SET_DEVICE:
      if (!state.devicesBySerial[action.payload.device.serial]) {
        return state;
      }

      return {
        ...state,
        devicesBySerial: {
          ...state.devicesBySerial,
          [action.payload.device.serial]: action.payload.device,
        },
      };
    case FILTER_DEVICES: {
      let { filters } = action.payload;
      filters = filters || {
        ...state.filters,
      };

      for (const k of Object.keys(filters)) {
        if (filters[k] === null) {
          delete filters[k];
        }
      }

      return {
        ...state,
        filters,
        devicesBySerial: {},
        devices: [],
        pagination: {},
      };
    }
    case SORT_DEVICES: {
      const { sortField, sortDirection } = action.payload;
      return {
        ...state,
        sortField,
        sortDirection,
      };
    }
    case TOGGLE_FILTERS: {
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    }
    case GET_DEVICE_SUCCESS: {
      return {
        ...state,
        selectedDevice: action.payload.device,
      };
    }
    case GET_DEVICE_GROUPS_SUCCESS:
      return {
        ...state,
        deviceGroups: action.payload.groups.device_groups,
        pagination: action.payload.groups.pagination,
      };
    case GET_DEVICE_GROUP_MEMBERSHIP_SUCCESS:
      const newDeviceGroupMembership = {
        ...state.deviceGroupMembership,
      };
      let restructuredDeviceList = [];
      for (let i = 0; action.payload.devices.length > i; i++) {
        restructuredDeviceList[action.payload.devices[i].serial] = action.payload.devices[i];
      }
      newDeviceGroupMembership[action.payload.groupId] = { ...restructuredDeviceList };
      return {
        ...state,
        deviceGroupMembership: {
          ...newDeviceGroupMembership,
        },
      };
    case CREATE_DEVICE_GROUPS_SUCCESS:
      return {
        ...state,
        deviceGroups: [...state.deviceGroups, action.payload.deviceGroup.device_group],
      };
    case UPDATE_DEVICE_GROUPS_SUCCESS:
      const deviceGroupList = [...state.deviceGroups];
      const updatedDeviceIndex = [...state.deviceGroups].findIndex(
        (group) => group.device_group_id === action.payload.deviceGroup.device_group.device_group_id
      );
      deviceGroupList[updatedDeviceIndex] = action.payload.deviceGroup.device_group;
      return {
        ...state,
        deviceGroups: deviceGroupList,
      };
    case DELETE_DEVICE_GROUPS_SUCCESS:
      return {
        ...state,
        deviceGroups: state.deviceGroups.filter((group) => group.device_group_id !== action.payload.groupId),
      };
    case DELETE_DEVICE_GROUP_MEMBERSHIP_SUCCESS:
      const newMembership = { ...state.deviceGroupMembership };
      delete newMembership[action.payload.groupId][action.payload.deviceId];
      return {
        ...state,
        deviceGroupMembership: newMembership,
      };
    case GET_DEVICE_TEMPLATES_SUCCESS: {
      return {
        ...state,
        managedApplications: {
          ...state.managedApplications,
          [action.payload.deviceType]: action.payload.managedApplications,
        },
      };
    }
    default:
      return state;
  }
};

export default Device;
