import { GET_DEBUG_EXPORTS_SUCCESS, GET_DEBUG_EXPORTS, UPDATE_DEBUG_EXPORT_SUCCESS, SET_DEBUG_EXPORT } from './constants';

const INIT_STATE = {
  exports: [],
  exportsById: {},
  pagination: {},
};

export const DebugExport = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEBUG_EXPORTS:
      // if (JSON.stringify(state.exportsById) !== JSON.stringify(action.payload.exportsById) ||
      //     JSON.stringify(state.exports) !== JSON.stringify(action.payload.exports)) {
      //       console.log('State changed in GET_DEBUG_EXPORTS:', action.payload.exportsById, action.payload.exports);
      //   return {
      //     ...state,
      //     exportsById: {},
      //     exports: [],
      //     pagination: {}
      //   };
      // } else {
        return state;
      // }
    case GET_DEBUG_EXPORTS_SUCCESS:
      const successDebugExportsById = {};
      const successDebugExports = action.payload.debug_exports.map((v) => {
        successDebugExportsById[v.debug_export_id] = v;
        return v.debug_export_id;
      });

      // Only update the state if the data has changed
      if (JSON.stringify(state.exportsById) !== JSON.stringify(successDebugExportsById) ||
          JSON.stringify(state.exports) !== JSON.stringify(successDebugExports)) {
        return {
          ...state,
          exportsById: successDebugExportsById,
          exports: successDebugExports,
          pagination: action.payload.pagination
        };
      } else {
        return state;
      }
    case UPDATE_DEBUG_EXPORT_SUCCESS:
    case SET_DEBUG_EXPORT:
      if (!state.exportsById[action.payload.debugExport.debug_export_id]) {
        return state;
      }

      return {
        ...state,
        exportsById: {
          ...state.exportsById,
          [action.payload.debugExport.debug_export_id]: action.payload.debugExport,
        },
      };
    default:
      return state;
  }
};

export default DebugExport;