export const REPLAY_BOOTHS = 'REPLAY_BOOTHS';
export const REPLAY_BOOTH = 'REPLAY_BOOTH';

export const GET_REPLAY_BOOTHS = 'GET/REPLAY_BOOTHS';
export const GET_REPLAY_BOOTHS_SUCCESS = 'GET/REPLAY_BOOTHS/SUCCESS';
export const GET_REPLAY_BOOTHS_FAILED = 'GET/REPLAY_BOOTHS/FAILED';

export const SET_REPLAY_BOOTH = 'SET/REPLAY_BOOTH';

export const UPDATE_REPLAY_BOOTH = 'SAVE/REPLAY_BOOTH';
export const UPDATE_REPLAY_BOOTH_SUCCESS = 'SAVE/REPLAY_BOOTH/SUCCESS';
export const UPDATE_REPLAY_BOOTH_FAILED = 'SAVE/REPLAY_BOOTH/FAILED';

export const PINNED_REPLAY_BOOTHS = 'PINNED_REPLAY_BOOTHS';
export const GET_PINNED_REPLAY_BOOTHS = 'GET/PINNED_REPLAY_BOOTHS';
export const GET_PINNED_REPLAY_BOOTHS_SUCCESS = 'GET/PINNED_REPLAY_BOOTHS/SUCCESS';
export const GET_PINNED_REPLAY_BOOTHS_FAILED = 'GET/PINNED_REPLAY_BOOTHS/FAILED';
export const PINNED_REPLAY_BOOTH = 'PINNED_REPLAY_BOOTH';
export const SAVE_PINNED_REPLAY_BOOTH = 'SAVE/PINNED_REPLAY_BOOTH';
export const SAVE_PINNED_REPLAY_BOOTH_SUCCESS = 'SAVE/PINNED_REPLAY_BOOTH/SUCCESS';
export const SAVE_PINNED_REPLAY_BOOTH_FAILED = 'SAVE/PINNED_REPLAY_BOOTH/FAILED';
