import { GET_DEBUG_EVENTS, GET_DEBUG_EVENTS_SUCCESS, GET_DEBUG_EVENTS_FAILED } from './constants';

export const getDebugEvents = (page = 1) => ({
  type: GET_DEBUG_EVENTS,
  payload: {
    page
  }
});

export const getDebugEventsSuccess = (debug_events, pagination) => ({
  type: GET_DEBUG_EVENTS_SUCCESS,
  payload: {
    debug_events,
    pagination,
  },
});

export const getDebugEventsFailed = (error) => ({
  type: GET_DEBUG_EVENTS_FAILED,
  payload: {
    error,
  },
});
