import { GET_ORGS_SUCCESS, GET_ORGS } from './constants';

const INIT_STATE = {
  orgsById: {},
  orgs: [],
  pagination: {},
};

export const Org = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ORGS:
      return {
        ...state,
        orgsById: {},
        orgs: [],
      };
    case GET_ORGS_SUCCESS:
      const successOrgsById = {};
      const successOrgs = action.payload.orgs.map((v) => {
        successOrgsById[v.org_id] = v;
        return v.org_id;
      });

      return {
        ...state,
        orgsById: successOrgsById,
        orgs: successOrgs,
      };
    default:
      return state;
  }
};

export default Org;
