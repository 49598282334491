import {
  GET_REPLAY_BOOTHS,
  GET_REPLAY_BOOTHS_SUCCESS,
  GET_REPLAY_BOOTHS_FAILED,
  GET_PINNED_REPLAY_BOOTHS,
  GET_PINNED_REPLAY_BOOTHS_SUCCESS,
  GET_PINNED_REPLAY_BOOTHS_FAILED,
  SAVE_PINNED_REPLAY_BOOTH,
  SAVE_PINNED_REPLAY_BOOTH_SUCCESS,
  SAVE_PINNED_REPLAY_BOOTH_FAILED,
  UPDATE_REPLAY_BOOTH,
  UPDATE_REPLAY_BOOTH_SUCCESS,
  UPDATE_REPLAY_BOOTH_FAILED,
  SET_REPLAY_BOOTH,
} from './constants';

export const getReplayBooths = (page = 1, search = {}, filters = {}, sort = {}) => ({
  type: GET_REPLAY_BOOTHS,
  payload: {
    page,
    search,
    filters,
    sort,
  },
});

export const getReplayBoothsSuccess = (replayBooths, pagination) => ({
  type: GET_REPLAY_BOOTHS_SUCCESS,
  payload: {
    replayBooths,
    pagination,
  },
});

export const getReplayBoothsFailed = (error) => ({
  type: GET_REPLAY_BOOTHS_FAILED,
  payload: {
    error,
  },
});

export const setReplayBooth = (replayBooth) => ({
  type: SET_REPLAY_BOOTH,
  payload: { replayBooth },
});

export const updateReplayBooth = (replayBoothId, notes) => ({
  type: UPDATE_REPLAY_BOOTH,
  payload: {
    replayBoothId,
    notes,
  },
});

export const updateReplayBoothSuccess = (replayBooth) => ({
  type: UPDATE_REPLAY_BOOTH_SUCCESS,
  payload: { replayBooth },
});

export const updateReplayBoothFailed = (error) => ({
  type: UPDATE_REPLAY_BOOTH_FAILED,
  payload: { error },
});

export const getPinnedReplayBooths = () => ({
  type: GET_PINNED_REPLAY_BOOTHS,
});

export const getPinnedReplayBoothsSuccess = (replayBooths) => ({
  type: GET_PINNED_REPLAY_BOOTHS_SUCCESS,
  payload: {
    replayBooths,
  },
});

export const getPinnedReplayBoothsFailed = (error) => ({
  type: GET_PINNED_REPLAY_BOOTHS_FAILED,
  payload: {
    error,
  },
});

export const togglePin = (replayBoothId, status) => ({
  type: SAVE_PINNED_REPLAY_BOOTH,
  payload: {
    replayBoothId,
    status,
  },
});

export const togglePinSuccess = (replayBooth) => ({
  type: SAVE_PINNED_REPLAY_BOOTH_SUCCESS,
  payload: {
    replayBooth,
  },
});

export const togglePinFailed = (error) => ({
  type: SAVE_PINNED_REPLAY_BOOTH_FAILED,
  payload: {
    error,
  },
});
