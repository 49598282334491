import {
  CREATE_USER_ROLE,
  CREATE_USER_ROLE_FAILED,
  CREATE_USER_ROLE_SUCCESS,
  GET_USER,
  GET_USERS,
  GET_USERS_FAILED,
  GET_USERS_SUCCESS,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  SET_CURRENT_USER,
  GET_USER_ROLES,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAILED,
} from './constants';

export const getUsers = (page = 1) => ({
  type: GET_USERS,
  payload: { page },
});

export const getUsersSuccess = (users, pagination) => ({
  type: GET_USERS_SUCCESS,
  payload: {
    users,
    pagination,
  },
});

export const getUsersFailed = (error) => ({
  type: GET_USERS_FAILED,
  payload: { error },
});

export const getUser = (userId) => ({
  type: GET_USER,
  payload: { userId },
});

export const getUserSuccess = (user) => ({
  type: GET_USER_SUCCESS,
  payload: { user },
});

export const getUserFailed = (error) => ({
  type: GET_USER_FAILED,
  payload: { error },
});

export const getUserRoles = (userId) => ({
  type: GET_USER_ROLES,
  payload: { userId },
});

export const getUserRolesSuccess = (roles) => ({
  type: GET_USER_ROLES_SUCCESS,
  payload: { roles },
});

export const getUserRolesFailed = (error) => ({
  type: GET_USER_ROLES_FAILED,
  payload: { error },
});

export const createUserRole = (userId, roles) => ({
  type: CREATE_USER_ROLE,
  payload: {
    userId,
    roles,
  },
});

export const createUserRoleSuccess = (roles) => ({
  type: CREATE_USER_ROLE_SUCCESS,
  payload: { roles },
});

export const createUserRoleFailed = (error) => ({
  type: CREATE_USER_ROLE_FAILED,
  payload: { error },
});

export const loginUser = (email, password, otpCode) => ({
  type: LOGIN_USER,
  payload: {
    email,
    password,
    otpCode,
  },
});

export const loginUserFailed = (error) => ({
  type: LOGIN_USER_FAILED,
  payload: { error },
});

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: { user },
});
