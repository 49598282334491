import React from 'react';
import { Redirect, Route } from 'react-router';

export default [
  {
    path: '/',
    name: 'Home',
    component: () => <Redirect to="/versions" />,
    route: Route,
    exact: true,
  },
  {
    path: '/orgs',
    name: 'Orgs',
    component: React.lazy(() => import('./pages/Orgs')),
    route: Route,
  },
  {
    path: '/users',
    name: 'Users',
    component: React.lazy(() => import('./pages/Users')),
    route: Route,
    exact: true,
  },
  {
    path: '/users/:id',
    name: 'User Roles',
    component: React.lazy(() => import('./pages/UsersDetails')),
    route: Route,
  },
  {
    path: '/devices',
    name: 'Devices',
    component: React.lazy(() => import('./pages/Devices')),
    route: Route,
    exact: true,
  },
  {
    path: '/debug-events',
    name: 'DebugEvents',
    component: React.lazy(() => import('./pages/DebugEvents')),
    route: Route,
    exact: true,
  },
  {
    path: '/debug-exports',
    name: 'DebugExports',
    component: React.lazy(() => import('./pages/DebugExports')),
    route: Route,
    exact: true,
  },
  {
    path: '/versions',
    name: 'Versions',
    component: React.lazy(() => import('./pages/Versions')),
    route: Route,
  },
  {
    path: '/replay-booths',
    name: 'ReplayBooths',
    component: React.lazy(() => import('./pages/ReplayBooths')),
    route: Route,
    exact: true,
  }
];
