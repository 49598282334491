import { GET_DEBUG_EVENTS_SUCCESS, GET_DEBUG_EVENTS } from './constants';

const INIT_STATE = {
  events: [],
  eventsById: {},
  pagination: {},
};

export const DebugEvent = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEBUG_EVENTS:
      return {
        ...state,
        eventsById: {},
        events: [],
        pagination: {}
      };
    case GET_DEBUG_EVENTS_SUCCESS:
      const successDebugEventsById = {};
      const successDebugEvents = action.payload.debug_events.map((v) => {
        successDebugEventsById[v.debug_event_id] = v;
        return v.debug_event_id;
      });
      const ret = {
        ...state,
        eventsById: successDebugEventsById,
        events: successDebugEvents,
        pagination: action.payload.pagination
      };
      return ret;
    default:
      return state;
  }
};

export default DebugEvent;
