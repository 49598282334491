import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import Main from '../layouts/Main';
import { Switch } from 'react-router';
import routes from '../routes';

export const Routes = (props) => {
  return (
    <BrowserRouter>
      <Main {...props}>
        <Switch>
          {routes.map((route, index) => {
            return (
              !route.children && (
                <route.route
                  key={index}
                  path={route.path}
                  roles={route.roles}
                  exact={route.exact}
                  component={route.component}></route.route>
              )
            );
          })}
        </Switch>
      </Main>
    </BrowserRouter>
  );
};

export default Routes;
