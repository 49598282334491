import { all } from 'redux-saga/effects';
import devicesSaga from './device/saga';
import versionsSaga from './version/saga';
import usersSaga from './user/saga';
import orgsSaga from './org/saga';
import debugEventsSaga from './debug_event/saga';
import debugExportsSaga from './debug_export/saga';
import replayBoothsSaga from './replay_booth/saga';

export default function* rootSaga() {
  yield all([devicesSaga(), versionsSaga(), usersSaga(), orgsSaga(), debugEventsSaga(), debugExportsSaga(), replayBoothsSaga()]);
}
