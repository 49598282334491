import { getJSON } from '../../fetch';
import { getDebugEventsSuccess, getDebugEventsFailed } from './actions';
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { GET_DEBUG_EVENTS } from './constants';

function* getDebugEvents({ payload: page }) {
  const response = yield call(getJSON, 'debug-events', page);

  if (response.success) {
    yield put(getDebugEventsSuccess(response.json.debug_events, response.json.pagination));
  } else {
    yield put(getDebugEventsFailed(response.message));
  }
}

export function* watchGetDebugEvents() {
  yield takeEvery(GET_DEBUG_EVENTS, getDebugEvents);
}

function* debugEventsSaga() {
  yield all([fork(watchGetDebugEvents)]);
}

export default debugEventsSaga;
