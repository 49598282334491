import { deleteJSON, getJSON, postJSON, putJSON } from '../../fetch';
import {
  createDeviceGroupsFailed,
  createDeviceGroupsSuccess,
  deleteDeviceGroupMembershipFailed,
  deleteDeviceGroupMembershipSuccess,
  deleteDeviceGroupsFailed,
  deleteDeviceGroupsSuccess,
  getDeviceFailed,
  getDeviceGroupMembershipFailed,
  getDeviceGroupMembershipSuccess,
  getDeviceGroupsFailed,
  getDeviceGroupsSuccess,
  getDevicesFailed,
  getDevicesSuccess,
  getDeviceSuccess,
  getDeviceTemplatesFailed,
  getDeviceTemplatesSuccess,
  updateDeviceFailed,
  updateDeviceGroupsFailed,
  updateDeviceGroupsSuccess,
  updateDeviceSuccess,
} from './actions';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  CREATE_DEVICE_GROUPS,
  DELETE_DEVICE_GROUP_MEMBERSHIP,
  DELETE_DEVICE_GROUPS,
  FILTER_DEVICES,
  GET_DEVICE,
  GET_DEVICE_GROUP_MEMBERSHIP,
  GET_DEVICE_GROUPS,
  GET_DEVICE_TEMPLATES,
  GET_DEVICES,
  SORT_DEVICES,
  UPDATE_DEVICE,
  UPDATE_DEVICE_GROUPS,
} from './constants';

function* getDevices({ payload: { page } }) {
  const { filters, sortField, sortDirection, pagination } = yield select((state) => state.Device);
  const response = yield call(getJSON, 'devices', {
    page: page || pagination.page || 1,
    ...filters,
    sort_field: sortField,
    sort_direction: sortDirection,
  });

  if (response.success) {
    yield put(getDevicesSuccess(response.json.devices, response.json.pagination));
  } else {
    yield put(getDevicesFailed(response.message));
  }
}

function* getDevice({ payload: { serial } }) {
  const response = yield call(getJSON, `devices/${serial}`, {});

  if (response.success) {
    yield put(getDeviceSuccess(response.json.device));
  } else {
    yield put(getDeviceFailed(response.message));
  }
}

function* getDeviceTemplates({ payload: { deviceType } }) {
  const response = yield call(getJSON, `device_templates/${deviceType}`, {});

  if (response.success) {
    yield put(getDeviceTemplatesSuccess(deviceType, response.json.managed_applications));
  } else {
    yield put(getDeviceTemplatesFailed(response.message));
  }
}

function* getDeviceGroupMembership({ payload: { groupId } }) {
  const response = yield call(getJSON, `device_groups/${groupId}/devices`, {});

  if (response.success) {
    yield put(getDeviceGroupMembershipSuccess(groupId, response.json.devices));
  } else {
    yield put(getDeviceGroupMembershipFailed(response.message));
  }
}

function* getDeviceGroups() {
  const response = yield call(getJSON, 'device_groups', {});
  if (response.success) {
    yield put(getDeviceGroupsSuccess(response.json));
  } else {
    yield put(getDeviceGroupsFailed(response.message));
  }
}

function* createDeviceGroups({ payload: { groupName, deviceSerials } }) {
  const response = yield call(postJSON, 'device_groups', {
    name: groupName,
    device_serials: deviceSerials,
  });
  if (response.success) {
    yield put(createDeviceGroupsSuccess(response.json));
  } else {
    yield put(createDeviceGroupsFailed(response.message));
  }
}

function* deleteDeviceGroups({ payload: { groupId } }) {
  const response = yield call(deleteJSON, `device_groups/${groupId}`, {});

  if (response.success) {
    yield put(deleteDeviceGroupsSuccess(groupId));
  } else {
    yield put(deleteDeviceGroupsFailed(response.message));
  }
}

function* updateDeviceGroups({ payload: { groupId, groupName, deviceSerials } }) {
  const response = yield call(putJSON, 'device_groups', {
    device_group_id: groupId,
    name: groupName,
    device_serials: deviceSerials,
  });

  if (response.success) {
    yield put(updateDeviceGroupsSuccess(response.json));
  } else {
    yield put(updateDeviceGroupsFailed(response.message));
  }
}

function* deleteDeviceGroupMembership({ payload: { groupId, groupName, deviceId } }) {
  const membership = (state) => state.Device.deviceGroupMembership[groupId];
  const selectedMembership = yield select(membership);
  const devices = Object.keys(selectedMembership).filter((device) => device !== deviceId);

  const response = yield call(putJSON, 'device_groups', {
    device_group_id: groupId,
    name: groupName,
    device_serials: devices,
  });

  if (response.success) {
    yield put(deleteDeviceGroupMembershipSuccess(groupId, deviceId));
  } else {
    yield put(deleteDeviceGroupMembershipFailed(response.message));
  }
}

export function* updateDevice({ payload: { deviceId, orgId, notes, additionalData } }) {
  const response = yield call(putJSON, `devices/${deviceId}`, {
    org_id: orgId,
    notes,
    additional_data: additionalData,
  });

  if (response.success) {
    yield put(updateDeviceSuccess(response.json));
  } else {
    yield put(updateDeviceFailed(response.message));
  }
}

export function* watchGetDeviceTemplates() {
  yield takeEvery(GET_DEVICE_TEMPLATES, getDeviceTemplates);
}

export function* watchGetDevice() {
  yield takeEvery(GET_DEVICE, getDevice);
}

export function* watchGetDevices() {
  yield takeEvery(GET_DEVICES, getDevices);
}

export function* watchUpdateDevice() {
  yield takeEvery(UPDATE_DEVICE, updateDevice);
}

export function* watchSetDeviceFilters() {
  yield takeEvery(FILTER_DEVICES, getDevices);
}

export function* watchSetDeviceSort() {
  yield takeEvery(SORT_DEVICES, getDevices);
}

export function* watchDeleteDeviceGroupMembership() {
  yield takeEvery(DELETE_DEVICE_GROUP_MEMBERSHIP, deleteDeviceGroupMembership);
}

export function* watchGetDeviceGroupMembership() {
  yield takeEvery(GET_DEVICE_GROUP_MEMBERSHIP, getDeviceGroupMembership);
}

export function* watchUpdateDeviceGroups() {
  yield takeEvery(UPDATE_DEVICE_GROUPS, updateDeviceGroups);
}

export function* watchCreateDeviceGroups() {
  yield takeEvery(CREATE_DEVICE_GROUPS, createDeviceGroups);
}

export function* watchGetDeviceGroups() {
  yield takeEvery(GET_DEVICE_GROUPS, getDeviceGroups);
}

export function* watchDeleteDeviceGroups() {
  yield takeEvery(DELETE_DEVICE_GROUPS, deleteDeviceGroups);
}

function* devicesSaga() {
  yield all([
    fork(watchGetDevices),
    fork(watchGetDevice),
    fork(watchUpdateDevice),
    fork(watchSetDeviceFilters),
    fork(watchSetDeviceSort),
    fork(watchGetDeviceTemplates),
    fork(watchGetDeviceGroups),
    fork(watchCreateDeviceGroups),
    fork(watchUpdateDeviceGroups),
    fork(watchDeleteDeviceGroups),
    fork(watchGetDeviceGroupMembership),
    fork(watchDeleteDeviceGroupMembership),
  ]);
}

export default devicesSaga;
