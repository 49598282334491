import { getJSON, postJSON } from '../../fetch';
import {
  getUserFailed,
  getUserSuccess,
  getUsersFailed,
  getUsersSuccess,
  loginUserFailed,
  setCurrentUser,
  createUserRoleSuccess,
  createUserRoleFailed,
  getUserRolesSuccess,
  getUserRolesFailed,
} from './actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_USERS, GET_USER, LOGIN_USER, GET_USER_ROLES, CREATE_USER_ROLE } from './constants';

function* getUsers({ payload: { page } }) {
  const response = yield call(getJSON, 'users', {
    page,
  });

  if (response.success) {
    yield put(getUsersSuccess(response.json.users, response.json.pagination));
  } else {
    yield put(getUsersFailed(response.message));
  }
}

function* loginUser({ payload: { email, password, otpCode } }) {
  const response = yield call(postJSON, 'users/login', {
    email,
    password,
    otp_code: otpCode,
  });

  if (response.success) {
    localStorage.setItem('token', response.json.token);
    yield put(setCurrentUser(response.json.user));
  } else {
    yield put(loginUserFailed(response.message));
  }
}

function* getUser({ payload: { userId } }) {
  const response = yield call(getJSON, `users/${userId}`, {});
  console.log('GET response', response);
  if (response.success) {
    yield put(getUserSuccess(response.json.user));
  } else {
    yield put(getUserFailed(response.message));
  }
}

function* getUserRoles({ payload: { userId } }) {
  const response = yield call(getJSON, `users/${userId}/roles`, {});

  if (response.success) {
    yield put(getUserRolesSuccess(response.json.role_assignments));
  } else {
    yield put(getUserRolesFailed(response.message));
  }
}

function* createUserRole({ payload: { userId, roles } }) {
  const response = yield call(postJSON, `users/${userId}/roles`, { roles: roles });

  if (response.success) {
    yield put(createUserRoleSuccess(response.json.role_assignments));
  } else {
    yield put(createUserRoleFailed(response.message));
  }
}

export function* watchCreateUserRole() {
  yield takeEvery(CREATE_USER_ROLE, createUserRole);
}

export function* watchGetUser() {
  yield takeEvery(GET_USER, getUser);
}

export function* watchGetUserRoles() {
  yield takeEvery(GET_USER_ROLES, getUserRoles);
}

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getUsers);
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginUser);
}

function* usersSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchGetUser),
    fork(watchLoginUser),
    fork(watchGetUserRoles),
    fork(watchCreateUserRole),
  ]);
}

export default usersSaga;
