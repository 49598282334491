export const DEBUG_EXPORTS = 'DEBUG_EXPORTS';
export const DEBUG_EXPORT = 'DEBUG_EXPORT';

export const GET_DEBUG_EXPORTS = 'GET/DEBUG_EXPORTS';
export const GET_DEBUG_EXPORTS_PERIODICALLY = 'GET_DEBUG_EXPORTS_PERIODICALLY';
export const GET_DEBUG_EXPORTS_SUCCESS = 'GET/DEBUG_EXPORTS/SUCCESS';
export const GET_DEBUG_EXPORTS_FAILED = 'GET/DEBUG_EXPORTS/FAILED';

export const SET_DEBUG_EXPORT = 'SET/DEBUG_EXPORT';

export const UPDATE_DEBUG_EXPORT = 'SAVE/DEBUG_EXPORT';
export const UPDATE_DEBUG_EXPORT_SUCCESS = 'SAVE/DEBUG_EXPORT/SUCCESS';
export const UPDATE_DEBUG_EXPORT_FAILED = 'SAVE/DEBUG_EXPORT/FAILED';

export const LAUNCH_DEBUG_VM = 'LAUNCH/DEBUG_VM';
