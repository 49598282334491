import { GET_ORGS, GET_ORGS_SUCCESS, GET_ORGS_FAILED } from './constants';

export const getOrgs = () => ({
  type: GET_ORGS,
});

export const getOrgsSuccess = (orgs) => ({
  type: GET_ORGS_SUCCESS,
  payload: {
    orgs,
  },
});

export const getOrgsFailed = (error) => ({
  type: GET_ORGS_FAILED,
  payload: {
    error,
  },
});
