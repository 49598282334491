import { useSelector } from 'react-redux';
import { useRef, useEffect } from 'react';

export const useLoading = (networkActionTypes) => {
  networkActionTypes = Array.isArray(networkActionTypes) ? networkActionTypes : [networkActionTypes];

  const networkState = useSelector((state) => state.Net);
  let isLoading = false;
  let error = '';
  for (const action of networkActionTypes) {
    const state = networkState[action];
    if (!state) {
      error = `${action} is not a valid network action`;
      isLoading = false;
      break;
    }

    isLoading = isLoading || state.isLoading;
    error = error || state.error;
  }

  return [isLoading, error];
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
