import { combineReducers } from 'redux';
import Net from './net/reducers';
import Device from './device/reducers';
import Version from './version/reducers';
import User from './user/reducers';
import Org from './org/reducers';
import DebugEvent from './debug_event/reducers';
import DebugExport from './debug_export/reducers';
import ReplayBooth from './replay_booth/reducers';

export default combineReducers({
  Version,
  Device,
  Net,
  User,
  Org,
  DebugEvent,
  DebugExport,
  ReplayBooth,
});
