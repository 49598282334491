import {
  GET_VERSIONS_SUCCESS,
  GET_VERSIONS,
  GET_PINNED_VERSIONS,
  GET_PINNED_VERSIONS_SUCCESS,
  SAVE_PINNED_VERSION_SUCCESS,
} from './constants';

const INIT_STATE = {
  versionsById: {},
  versions: [],
  pinnedVersions: [],
  pagination: {},
};

export const Version = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VERSIONS:
      return {
        ...state,
        versions: [],
        pagination: {},
      };
    case GET_VERSIONS_SUCCESS:
      const successVersionsById = {};
      const successVersions = action.payload.versions.map((v) => {
        successVersionsById[v.version_id] = v;
        return v.version_id;
      });

      return {
        ...state,
        versionsById: {
          ...state.versionsById,
          ...successVersionsById,
        },
        versions: successVersions,
        pagination: action.payload.pagination,
      };
    case GET_PINNED_VERSIONS:
      return {
        ...state,
        pinnedVersions: [],
      };
    case GET_PINNED_VERSIONS_SUCCESS:
      const pinnedVersionsById = {};
      const pinnedVersions = action.payload.versions.map((v) => {
        pinnedVersionsById[v.version_id] = v;
        return v.version_id;
      });

      return {
        ...state,
        pinnedVersions,
        versionsById: {
          ...state.versionsById,
          ...pinnedVersionsById,
        },
      };
    case SAVE_PINNED_VERSION_SUCCESS:
      const { version: pinnedVersionToSave } = action.payload;
      let { pinnedVersions: savePinnedVersions } = state;
      savePinnedVersions = [].concat(savePinnedVersions);
      if (pinnedVersionToSave.is_pinned) {
        savePinnedVersions.push(pinnedVersionToSave.version_id);
      } else {
        const idx = savePinnedVersions.findIndex((versionId) => versionId === pinnedVersionToSave.version_id);
        savePinnedVersions.splice(idx, 1);
      }

      savePinnedVersions.sort((a, b) => b - a);

      return {
        ...state,
        versionsById: {
          ...state.versionsById,
          [pinnedVersionToSave.version_id]: pinnedVersionToSave,
        },
        pinnedVersions: savePinnedVersions,
      };
    default:
      return state;
  }
};

export default Version;
