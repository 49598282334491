import {
  GET_REPLAY_BOOTHS_SUCCESS,
  GET_REPLAY_BOOTHS,
  GET_PINNED_REPLAY_BOOTHS,
  GET_PINNED_REPLAY_BOOTHS_SUCCESS,
  SAVE_PINNED_REPLAY_BOOTH_SUCCESS,
  UPDATE_REPLAY_BOOTH,
  UPDATE_REPLAY_BOOTH_SUCCESS,
  UPDATE_REPLAY_BOOTH_FAILED,
  SET_REPLAY_BOOTH,
} from './constants';

const INIT_STATE = {
  replayBoothsById: {},
  replayBooths: [],
  pinnedReplayBooths: [],
  pagination: {},
};

export const ReplayBooth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPLAY_BOOTHS:
      return {
        ...state,
        replayBooths: [],
        pagination: {},
      };
    case GET_REPLAY_BOOTHS_SUCCESS:
      const successReplayBoothsById = {};
      const successReplayBooths = action.payload.replayBooths.map((v) => {
        successReplayBoothsById[v.replay_booth_vm_id] = v;
        return v.replay_booth_vm_id;
      });

      return {
        ...state,
        replayBoothsById: {
          ...state.replayBoothsById,
          ...successReplayBoothsById,
        },
        replayBooths: successReplayBooths,
        pagination: action.payload.pagination,
      };
    case UPDATE_REPLAY_BOOTH_SUCCESS:
    case SET_REPLAY_BOOTH:
      if (!state.replayBoothsById[action.payload.replayBooth.replay_booth_vm_id]) {
        return state;
      }

      return {
        ...state,
        replayBoothsById: {
          ...state.replayBoothsById,
          [action.payload.replayBooth.replay_booth_vm_id]: action.payload.replayBooth,
        },
      };
    case GET_PINNED_REPLAY_BOOTHS:
      return {
        ...state,
        pinnedReplayBooths: [],
      };
    case GET_PINNED_REPLAY_BOOTHS_SUCCESS:
      const pinnedReplayBoothsById = {};
      const pinnedReplayBooths = action.payload.replayBooths.map((v) => {
        pinnedReplayBoothsById[v.replay_booth_vm_id] = v;
        return v.replay_booth_vm_id;
      });

      return {
        ...state,
        pinnedReplayBooths,
        replayBoothsById: {
          ...state.replayBoothsById,
          ...pinnedReplayBoothsById,
        },
      };
    case SAVE_PINNED_REPLAY_BOOTH_SUCCESS:
      const { replayBooth: pinnedReplayBoothToSave } = action.payload;
      let { pinnedReplayBooths: savePinnedReplayBooths } = state;
      savePinnedReplayBooths = [].concat(savePinnedReplayBooths);
      if (pinnedReplayBoothToSave.is_pinned) {
        savePinnedReplayBooths.push(pinnedReplayBoothToSave.replay_booth_vm_id);
      } else {
        const idx = savePinnedReplayBooths.findIndex((replayBoothId) => replayBoothId === pinnedReplayBoothToSave.replay_booth_vm_id);
        savePinnedReplayBooths.splice(idx, 1);
      }

      savePinnedReplayBooths.sort((a, b) => b - a);

      return {
        ...state,
        replayBoothsById: {
          ...state.replayBoothsById,
          [pinnedReplayBoothToSave.replay_booth_vm_id]: pinnedReplayBoothToSave,
        },
        pinnedReplayBooths: savePinnedReplayBooths,
      };
    default:
      return state;
  }
};

export default ReplayBooth;
