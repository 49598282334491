import { getJSON, postJSON, patchJSON } from '../../fetch';
import { getDebugExportsSuccess, getDebugExportsFailed, updateDebugExportSuccess, updateDebugExportFailed } from './actions';
import { select, call, put, takeEvery, takeLatest, all, fork, delay } from 'redux-saga/effects';
import { GET_DEBUG_EXPORTS, GET_DEBUG_EXPORTS_PERIODICALLY, LAUNCH_DEBUG_VM, UPDATE_DEBUG_EXPORT } from './constants';

function* getDebugExports({ payload: page }) {
  const response = yield call(getJSON, 'debug-exports', {
    'include_num_station_audit_reports': 'true',
    'include_num_cycles_audited': 'true',
    page
  });

  if (response.success) {
    yield put(getDebugExportsSuccess(response.json.debug_exports, response.json.pagination));
  } else {
    yield put(getDebugExportsFailed(response.message));
  }
}

function* launchDebugVM({ payload }) {
  yield call(postJSON, 'debug-vms', payload.vm)
}

export function* watchGetDebugExports() {
  yield takeEvery(GET_DEBUG_EXPORTS, getDebugExports);
}

export function* watchLaunchDebugVM() {
  yield takeEvery(LAUNCH_DEBUG_VM, launchDebugVM);
}

function* getDebugExportsPeriodically() {
  let currentPage = 1;
  while (true) {
    yield put({ type: GET_DEBUG_EXPORTS, payload: currentPage });
    const debugExports = yield select(state => state.debugExports);
    if (debugExports && debugExports.pagination) {
      currentPage = debugExports.pagination.current_page;
    }
    yield delay(5000); // delay for 5 seconds
  }
}

export function* watchGetDebugExportsPeriodically() {
  yield takeLatest(GET_DEBUG_EXPORTS_PERIODICALLY, getDebugExportsPeriodically);
}

export function* updateDebugExport({ payload: { debugExportId, notes } }) {
  const response = yield call(patchJSON, `debug-exports/${debugExportId}`, { notes });

  if (response.success) {
    yield put(updateDebugExportSuccess(response.json));
  } else {
    yield put(updateDebugExportFailed(response.message));
  }
}

export function* watchUpdateDebugExport() {
  yield takeEvery(UPDATE_DEBUG_EXPORT, updateDebugExport);
}

function* debugExportsSaga() {
  yield all([
    fork(watchGetDebugExports), 
    fork(watchLaunchDebugVM),
    fork(watchGetDebugExportsPeriodically),
    fork(watchUpdateDebugExport),
  ]);
}

export default debugExportsSaga;
