import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

const SideMenu = (props) => {
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem('token');
    history.push('/');
  };

  const links = [
    {
      to: '/orgs',
      label: 'Orgs',
      icon: 'office-building',
    },
    {
      to: '/users',
      label: 'Users',
      icon: 'account',
    },
    {
      to: '/devices',
      label: 'Devices',
      icon: 'camera',
    },
    {
      to: '/versions',
      label: 'Versions',
      icon: 'source-branch',
    },
    {
      to: '/debug-events',
      label: 'Debug Events',
      icon: 'alert-box',
    },
    {
      to: '/debug-exports',
      label: 'Debug Exports',
      icon: 'package-variant',
    },
    {
      to: '/replay-booths',
      label: 'Replay Booths',
      icon: 'replay',
    }
  ];

  return (
    <div className={'side-menu-custom' + (props.isMenuOpened ? ' expanded' : '')}>
      <div className="content">
        <div className="links-list">
          {links.map((link) => {
            return (
              <NavLink className="link-item" to={link.to} key={link.to} activeClassName="active">
                <i className={'mdi mdi-' + link.icon} />
                <div className="label">{link.label}</div>
              </NavLink>
            );
          })}
          <a href="#" className="link-item" onClick={logout}>
            <i className="mdi mdi-logout-variant" />
            <div className="label">Log Out</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
