import { getJSON } from '../../fetch';
import { getOrgsSuccess, getOrgsFailed } from './actions';
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { GET_ORGS } from './constants';

function* getOrgs() {
  const response = yield call(getJSON, 'orgs');

  if (response.success) {
    yield put(getOrgsSuccess(response.json.orgs));
  } else {
    yield put(getOrgsFailed(response.message));
  }
}

export function* watchGetOrgs() {
  yield takeEvery(GET_ORGS, getOrgs);
}

function* orgsSaga() {
  yield all([fork(watchGetOrgs)]);
}

export default orgsSaga;
