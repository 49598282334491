import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo_full_light.png';

export const Navbar = (props) => {
  return (
    <div className="navbar-custom navbar-dark">
      <div className="content">
        <div className="overlay" />

        <div className="side-menu-icon" onClick={props.toggleMenu}>
          <i className="icon mdi mdi-menu" />
        </div>

        <Link to="/home" className="navbar-logo">
          <span className="logo-icon">
            <img src={logo} alt="logo" />
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
