import { DEVICE, DEVICE_GROUP_MEMBERSHIP, DEVICE_GROUPS, DEVICE_TEMPLATES, DEVICES } from '../device/constants';
import { PINNED_VERSIONS, VERSIONS } from '../version/constants';
import { USER_LOGIN, USER_ROLE, USERS } from '../user/constants';
import { LOGS } from '../log/constants';
import { ORGS } from '../org/constants';

const INIT_STATE = {
  [VERSIONS]: {
    isLoading: false,
    error: null,
  },
  [PINNED_VERSIONS]: {
    isLoading: false,
    error: null,
  },
  [DEVICES]: {
    isLoading: false,
    error: null,
  },
  [USERS]: {
    isLoading: false,
    error: null,
  },
  [USER_ROLE]: {
    isLoading: false,
    error: null,
  },
  [LOGS]: {
    isLoading: false,
    error: null,
  },
  [ORGS]: {
    isLoading: false,
    error: null,
  },
  [DEVICE]: {
    isLoading: false,
    error: null,
  },
  [DEVICE_GROUPS]: {
    isLoading: false,
    error: null,
  },
  [DEVICE_GROUP_MEMBERSHIP]: {
    isLoading: false,
    error: null,
  },
  [DEVICE_TEMPLATES]: {
    isLoading: false,
    error: null,
  },
  [USER_LOGIN]: {
    isLoading: false,
    error: null,
  },
};

const Net = (state = INIT_STATE, action) => {
  const { type } = action;
  const regex = /^(GET|SAVE|DELETE|FILTER|SORT|UPDATE|CREATE)\/([\w_]+)\/?(SUCCESS|FAILED)?$/g;
  const networkAction = regex.exec(type);

  if (!networkAction) {
    return state;
  }

  let [_, __, networkActionType, status] = networkAction;

  switch (status) {
    case 'SUCCESS':
      return {
        ...state,
        [networkActionType]: {
          isLoading: false,
          error: null,
        },
      };
    case 'FAILED':
      return {
        ...state,
        [networkActionType]: {
          isLoading: false,
          error: action.payload.error,
        },
      };
    default:
      return {
        ...state,
        [networkActionType]: {
          isLoading: true,
          error: null,
        },
      };
  }
};

export default Net;
