import { getJSON, postJSON, deleteJSON, dataTableParams } from '../../fetch';
import {
  getVersionsSuccess,
  getVersionsFailed,
  getPinnedVersionsSuccess,
  getPinnedVersionsFailed,
  togglePinSuccess,
  togglePinFailed,
} from './actions';
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { GET_VERSIONS, GET_PINNED_VERSIONS, SAVE_PINNED_VERSION } from './constants';

function* getVersions({ payload: { page, search, filters, sort } }) {
  const response = yield call(getJSON, 'versions', {
    page,
    ...dataTableParams(search, filters, sort),
  });

  if (response.success) {
    yield put(getVersionsSuccess(response.json.versions, response.json.pagination));
  } else {
    yield put(getVersionsFailed(response.message));
  }
}

function* getPinnedVersions() {
  const response = yield call(getJSON, 'versions/pinned');

  if (response.success) {
    yield put(getPinnedVersionsSuccess(response.json.versions, response.json.pagination));
  } else {
    yield put(getPinnedVersionsFailed(response.message));
  }
}

function* savePinnedVersion({ payload: { versionId, status } }) {
  const response = yield call(status ? postJSON : deleteJSON, `versions/${versionId}/pin`);

  if (response.success) {
    yield put(togglePinSuccess(response.json));
  } else {
    yield put(togglePinFailed(response.message));
  }
}

export function* watchGetVersions() {
  yield takeEvery(GET_VERSIONS, getVersions);
}

export function* watchGetPinnedVersions() {
  yield takeEvery(GET_PINNED_VERSIONS, getPinnedVersions);
}

export function* watchSavePinnedVersion() {
  yield takeEvery(SAVE_PINNED_VERSION, savePinnedVersion);
}

function* versionsSaga() {
  yield all([fork(watchGetVersions), fork(watchGetPinnedVersions), fork(watchSavePinnedVersion)]);
}

export default versionsSaga;
