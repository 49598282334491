export const USERS = 'USERS';
export const GET_USERS = 'GET/USERS';
export const GET_USERS_SUCCESS = 'GET/USERS/SUCCESS';
export const GET_USERS_FAILED = 'GET/USERS/FAILED';

export const USER = 'USER';
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET/USER/SUCCESS';
export const GET_USER_FAILED = 'GET/USER/FAILED';

export const USER_ROLES = 'USER_ROLES';
export const GET_USER_ROLES = 'GET/USER_ROLES';
export const GET_USER_ROLES_SUCCESS = 'GET/USER_ROLES/SUCCESS';
export const GET_USER_ROLES_FAILED = 'GET/USER_ROLES/FAILED';

export const USER_ROLE = 'USER_ROLE';
export const CREATE_USER_ROLE = 'SAVE/USER_ROLE';
export const CREATE_USER_ROLE_SUCCESS = 'SAVE/USER_ROLE/SUCCESS';
export const CREATE_USER_ROLE_FAILED = 'SAVE/USER_ROLE/FAILED';

export const USER_LOGIN = 'USER_LOGIN';
export const LOGIN_USER = 'SAVE/USER_LOGIN';
export const LOGIN_USER_FAILED = 'SAVE/USER_LOGIN/FAILED';
export const SET_CURRENT_USER = 'SAVE/USER_LOGIN/SUCCESS';
