import React, { useEffect, useState } from 'react';
import './assets/scss/app.scss';
import Routes from './components/Routes';
import { Alert, Button } from 'reactstrap';
import logo from './assets/images/logo_full_light.png';
import { getJSON } from './fetch';
import { loginUser, setCurrentUser } from './redux/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Loader from './components/Loader';
import { useLoading } from './hooks';
import { USER_LOGIN } from './redux/user/constants';

const App = () => {
  const dispatch = useDispatch();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const { currentUser } = useSelector((state) => state.User);
  const [isLoggingIn, isLoggingInError] = useLoading([USER_LOGIN]);

  useEffect(() => {
    (async function () {
      if (!localStorage.getItem('token')) {
        setIsCheckingAuth(false);
        return;
      }

      const meRes = await getJSON('users/me');
      setIsCheckingAuth(false);
      if (meRes.success) {
        dispatch(setCurrentUser(meRes.json));
      }
    })();
  }, []);

  const onLogin = (e, values) => {
    dispatch(loginUser(values.email, values.password, values.otpCode));
  };

  if (isCheckingAuth) {
    return <Loader />;
  }

  if (currentUser) {
    return <Routes />;
  }

  return (
    <div className="login-page">
      <div className="form-side">
        <div className="content">
          <h2 className="title mt-0 mb-4">Log In</h2>

          <Alert color="danger" isOpen={!!isLoggingInError}>
            {isLoggingInError}
          </Alert>

          <AvForm onValidSubmit={onLogin}>
            <AvField type="email" name="email" label="E-Mail:" placeholder="user@company.com" required />

            <AvField type="password" name="password" label="Password:" required />

            <AvField type="text" name="otpCode" label="OTP Code:" required />

            <div className="form-group">
              <Button color="primary" type="submit">
                {isLoggingIn ? 'Logging in...' : 'Log In'}
              </Button>

              <p className="text-muted font-12 mt-2 mb-0">If you forgot your password, please contact us for help.</p>
            </div>
          </AvForm>
        </div>
      </div>

      <div className="banner-side">
        <div className="overlay">
          <div className="content">
            <div className="logo ">
              <img src={logo} alt="Invisible AI" />
            </div>

            <h3 className="text mt-3 mb-0">Bringing the power of real-time computer vision to Industry 4.0</h3>

            <div className="line" />

            <div className="benefits-list">
              <div className="item">
                <p className="text m-0">
                  <i className="icon mdi mdi-clock" />
                  Fast deployment times
                </p>
              </div>

              <div className="item">
                <p className="text m-0">
                  <i className="icon mdi mdi-webcam" />
                  AI-enabled cameras
                </p>
              </div>

              <div className="item">
                <p className="text m-0">
                  <i className="icon mdi mdi-keyboard-off" />
                  No coding required
                </p>
              </div>
            </div>

            <p className="mt-5 mb-0">
              For all inquiries, please&nbsp;
              <a href="https://www.invisible.ai/contact" target="_blank" rel="noopener noreferrer">
                contact us
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
