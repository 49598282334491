export const getJSON = (path, queryParams = {}) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/${path}${createQueryString(queryParams) || ''}`;

  return handleResponse(
    doFetch(url, {
      method: 'GET',
    })
  );
};

export const getData = (path, queryParams = {}) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/${path}${createQueryString(queryParams) || ''}`;

  return doFetch(url, {
    method: 'GET'
  });
}

export const postJSON = (path, body) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/${path}`;

  return handleResponse(
    doFetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })
  );
};

export const putJSON = (path, body) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/${path}`;

  return handleResponse(
    doFetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
  );
};

export const patchJSON = (path, body) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/${path}`;

  return handleResponse(
    doFetch(url, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })
  );
};

export const deleteJSON = (path, body) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/${path}`;

  return handleResponse(
    doFetch(url, {
      method: 'DELETE',
      body: JSON.stringify(body),
    })
  );
};

function doFetch(url, opts) {
  opts = {
    ...opts,
    headers: {
      ...opts.headers,
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    credentials: 'include',
  };

  return fetch(url, opts);
}

export const handleResponse = (p) =>
  p.then(
    (rawResponse) => {
      if (rawResponse.status < 200 || rawResponse.status > 299) {
        return rawResponse
          .json()
          .then((jsonBody) => {
            return {
              success: false,
              statusCode: rawResponse.status,
              message: jsonBody.message,
            };
          })
          .catch((e) => {
            return {
              success: false,
              statusCode: rawResponse.status,
              message: e.message,
              errorParsingBody: true,
            };
          });
      }

      if (rawResponse.status === 204) {
        return {
          success: true,
          statusCode: 204,
          json: null,
        };
      }

      return rawResponse
        .json()
        .then((jsonBody) => {
          return {
            success: true,
            statusCode: rawResponse.status,
            json: jsonBody,
          };
        })
        .catch((e) => {
          return {
            success: false,
            statusCode: rawResponse.status,
            message: e.message,
            errorParsingBody: true,
          };
        });
    },
    (err) => {
      return {
        success: false,
        statusCode: null,
        message: err.message,
      };
    }
  );

export const createQueryString = (params) => {
  const paramKeys = Object.keys(params);
  let ret = '';
  if (paramKeys.length) {
    ret = '?' + paramKeys.map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');
  }
  return ret;
};

export const dataTableParams = (search, filters, sort) => {
  const parameters = {};
  if (search && search.field && search.field.length > 0 && search.query.length > 0) {
    parameters['search'] = search.query;
  }
  if (filters) {
    for (const key of Object.keys(filters)) {
      if (!!filters[key]) {
        parameters[`filter_${key}`] = filters[key];
      }
    }
  }
  if (sort && sort.field && sort.field.length > 0) {
    parameters.sort_field = sort.field;
    parameters.sort_direction = sort.direction;
  }
  return parameters;
};
