import { getJSON, postJSON, patchJSON, deleteJSON, dataTableParams } from '../../fetch';
import {
  getReplayBoothsSuccess,
  getReplayBoothsFailed,
  getPinnedReplayBoothsSuccess,
  getPinnedReplayBoothsFailed,
  togglePinSuccess,
  togglePinFailed,
  updateReplayBoothSuccess,
  updateReplayBoothFailed,
} from './actions';
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { GET_REPLAY_BOOTHS, GET_PINNED_REPLAY_BOOTHS, SAVE_PINNED_REPLAY_BOOTH, UPDATE_REPLAY_BOOTH } from './constants';

function* getReplayBooths({ payload: { page, search, filters, sort } }) {
  const response = yield call(getJSON, 'replay-booth-vms', {
    'include_debug_export': 'true',
    page,
    ...dataTableParams(search, filters, sort),
  });

  if (response.success) {
    yield put(getReplayBoothsSuccess(response.json.replay_booth_vms, response.json.pagination));
  } else {
    yield put(getReplayBoothsFailed(response.message));
  }
}

function* getPinnedReplayBooths() {
  const response = yield call(getJSON, 'replay-booth-vms/pinned');

  if (response.success) {
    yield put(getPinnedReplayBoothsSuccess(response.json.replayBooths, response.json.pagination));
  } else {
    yield put(getPinnedReplayBoothsFailed(response.message));
  }
}

function* updateReplayBooth({ payload: { replayBoothId, notes } }) {
  const response = yield call(patchJSON, `replay-booth-vms/${replayBoothId}`, { notes });

  if (response.success) {
    yield put(updateReplayBoothSuccess(response.json));
  } else {
    yield put(updateReplayBoothFailed(response.message));
  }
}

function* savePinnedReplayBooth({ payload: { replayBoothId, status } }) {
  const response = yield call(status ? postJSON : deleteJSON, `replay-booth-vms/${replayBoothId}/pin`);

  if (response.success) {
    yield put(togglePinSuccess(response.json));
  } else {
    yield put(togglePinFailed(response.message));
  }
}

export function* watchGetReplayBooths() {
  yield takeEvery(GET_REPLAY_BOOTHS, getReplayBooths);
}

export function* watchUpdateReplayBooth() {
  yield takeEvery(UPDATE_REPLAY_BOOTH, updateReplayBooth);
}

export function* watchGetPinnedReplayBooths() {
  yield takeEvery(GET_PINNED_REPLAY_BOOTHS, getPinnedReplayBooths);
}

export function* watchSavePinnedReplayBooth() {
  yield takeEvery(SAVE_PINNED_REPLAY_BOOTH, savePinnedReplayBooth);
}

function* replayBoothsSaga() {
  yield all([
    fork(watchGetReplayBooths), 
    fork(watchGetPinnedReplayBooths), 
    fork(watchSavePinnedReplayBooth),
    fork(watchUpdateReplayBooth),
  ]);
}

export default replayBoothsSaga;
